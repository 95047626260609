import React, { FC } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { formCheck } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'

type Props = {
	currentStep: number
	steps: string[]
}

type ClassType = {
	root: string
	step: string
	circle: string
	text: string
	icon: string
}

const classes: ClassType = makeClasses({
	root: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'.steps': {
			'&:first-child': {
				'&::before': {
					display: 'none'
				}
			},
			'&:last-child': {
				'&::after': {
					display: 'none'
				}
			}
		},
		[Breakpoints.maxWidth('md')]: {
			marginBottom: '30px'
		}
	},
	step: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		maxWidth: '150px',
		width: '100%',
		color: '#666666',
		'&.step-complete': {
			color: '#0054A6',
			'.circle': {
				background: '#0054A6',
				borderColor: '#0054A6'
			},
			span: {
				display: 'inline',
				color: Colors.white
			}
		},
		'&.step-current': {
			color: '#0054A6',
			'.circle': {
				borderColor: '#0054A6'
			}
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			top: 'calc((32px / 2) - 1.5px)',
			right: '0',
			width: 'calc((100% - 40px) /2)',
			height: '3px',
			background: '#cccccc',
			[Breakpoints.maxWidth('md')]: {
				width: '10px',
				zIndex: '-1'
			}
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 'calc((32px / 2) - 1.5px)',
			left: '0',
			width: 'calc((100% - 40px) /2)',
			height: '3px',
			background: '#cccccc',
			[Breakpoints.maxWidth('md')]: {
				width: '10px',
				zIndex: '-1'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			maxWidth: '65px'
		},
		[Breakpoints.minWidth('md')]: {
			height: '68px'
		}
	},
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '32px',
		height: '32px',
		fontSize: '16px',
		fontWeight: 'bold',
		lineHeight: '26px',
		border: '3px solid #cccccc',
		borderRadius: '16px'
	},
	text: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '90%',
		height: '100%',
		fontSize: '12px',
		lineHeight: '14px',
		textAlign: 'center',
		fontWeight: 700,
		marginTop: '8px',
		[Breakpoints.maxWidth('md')]: {
			display: 'none'
		}
	},
	icon: {
		width: '26px',
		height: '32px',
		display: 'none'
	}
})

const ProgressBar: FC<Props> = ({ currentStep, steps }) => {
	const createSteps = (step, index) => {
		const complete: string = index + 1 < currentStep ? 'step-complete' : ''
		const current: string = index + 1 === currentStep ? 'step-current' : ''

		return (
			<div
				className={joinClasses([classes.step, 'steps', current, complete])}
				key={index}
			>
				<div className={joinClasses([classes.circle, 'circle'])}>
					<span>{index + 1}</span>
				</div>
				<div className={classes.text}>{step}</div>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{steps.map((step, index) => createSteps(step, index))}
		</div>
	)
}

export default ProgressBar
