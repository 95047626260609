import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import Text from '@components/ui/text'
import {
	classes,
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from './__index.utils'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import {
	AddressInputName,
	RadioList,
	RequestInputs,
	TNRequestNames
} from '@utils/request'
import { Direction, REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'
import requestForm from '@components/requestForms/request.form'
import MapForm from '../map/MapForm'
import { joinClasses } from '@utils/styles'
import { MapTNProps } from '@components/requestForms/__forms.utils'
import { Coordinate } from '../map/mapForm.interfaces'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { useAppSelector } from '@services/store'
import { AddressCoordinates, CustomDate } from '@services/models'
import DisplayInforModal from '../modals/displayInfoModal'
import { navigate } from '@components/ui/link'
import moment from 'moment'
import AddressValidationModal from '../addressValidationModal'
import DateFilterInput from '@components/requestForms/inputs/datefilter.input'
import Notification from '../notification'

const AddressForm: FC<MapTNProps> = ({
	form,
	errors,
	inputs,
	hasError,
	onAddError,
	onFixError,
	setMapLabel,
	setIsRequired,
	onSetInputs,
	onAddressChange,
	onAddressInputChange,
	onRetreiveCoordinates,
	onApartmentInputChange,
	setUseDefaultUserLocation,
	onSetPostalCode,
	onSetCity,
	setDisableNextButton,
	closeLink,
	renderAddressValidation,
	setShouldCheckAddress,
	setIsRequestAddressOutsideLaval,
	isAddressOutsideLavalMessageShow,
	setIsAddressOutsideLavalMessageShow
}) => {
	const { authUser } = useContext<AppContextProps>(AppStateContext)

	const [addressShouldChange, setAddressShouldChange] = useState<boolean>(false)

	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)
	const customLocationState = useAppSelector((state) => state.request.location)
	const shouldDisplaySelectByLot =
		customLocationState.cadastralAddress && answers.buildingCadastralNumber
	const [selectByLot, setSelectByLot] = useState<boolean>(
		shouldDisplaySelectByLot ? true : false
	)

	const [cadastre, setCadastre] = useState<string | undefined>(
		answers.buildingCadastralNumber
	)
	const [postalCode, setPostalCode] = useState<string | undefined>()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [isTenthQuestionNotificationOpen, setIsTenthQuestionNotificationOpen] =
		useState<boolean>(false)

	const [
		isEleventhQuestionNotificationOpen,
		setIsEleventhQuestionNotificationOpen
	] = useState<boolean>(false)

	const [userCadastralNumber, setUserCadastralNumber] = useState<
		string | undefined
	>()

	const [firstQuestion, setFirstQuestion] = useState<RequestInputs>({
		name: 'request_form_is_address_correct',
		label:
			form === TNRequestNames.treePlanting
				? pageAssets.request_form_is_plantation_location
				: form === TNRequestNames.rainwaterBarrel
				? pageAssets.request_form_is_address_that_of_work
				: form ===
				  TNRequestNames.temporaryPesticideApplicationPermitForIndividuals
				? pageAssets.request_form_property_address_is_correct
				: pageAssets.request_form_is_address_correct,
		labelKey: 'request_form_is_address_correct',
		value:
			customLocationState.useDefaultUserLocation == true
				? REQUEST_CONFIGS_KEYS.yes
				: REQUEST_CONFIGS_KEYS.no,
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [secondQuestion, setSecondQuestion] = useState<RequestInputs>({
		name: 'buildingCadastralNumber',
		label: pageAssets?.request_form_mapCadastral_label,
		labelKey: 'request_form_mapCadastral_label',
		value: cadastre || '',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [thirdQuestion, setThirdQuestion] = useState<RequestInputs>({
		name: 'request_form_building_location',
		label:
			form === TNRequestNames.treePlanting
				? pageAssets.request_form_step_map_location
				: form ===
				  TNRequestNames.temporaryPesticideApplicationPermitForIndividuals
				? pageAssets.request_form_property_location
				: pageAssets.request_form_building_location,
		labelKey: 'request_form_building_location',
		value: !shouldDisplaySelectByLot
			? REQUEST_CONFIGS_KEYS.buildingLocation.byAdress
			: REQUEST_CONFIGS_KEYS.buildingLocation.byLot,
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fourthQuestion, setFourthQuestion] = useState<RequestInputs>({
		name: 'isOverYearOwner',
		label: pageAssets.request_form_locationOwnedMoreThenAYear_label,
		labelKey: 'request_form_locationOwnedMoreThenAYear_label',
		value: answers?.isOverYearOwner == false ? 'false' : 'true',
		required: [TNRequestNames.treePlanting].includes(String(form)),
		ref: useRef<HTMLInputElement>(null)
	})

	const [fifthQuestion, setFifthQuestion] = useState<RequestInputs>({
		name: 'notarialRegistrationDate',
		label: pageAssets.request_form_notarialRegistrationDate_label,
		labelKey: 'request_form_notarialRegistrationDate_label',
		value: answers?.notarialRegistrationDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		extraParams: {
			type: 'date',
			max: CustomDate.currentDate(),
			min: moment().subtract(1, 'years').format('YYYY-MM-DD'),
			customErrorText:
				pageAssets?.request_form_notarialRegistrationDate_customTextError
		}
	})

	const [sixthQuestion, setSixthQuestion] = useState<RequestInputs>({
		name: 'notarialRegistrationNumber',
		label: pageAssets.request_form_notarialRegistrationNumber_label,
		labelKey: 'request_form_notarialRegistrationNumber_label',
		value: answers.notarialRegistrationNumber || '',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [ninethQuestion, setNinethQuestion] = useState<RequestInputs>({
		name: 'propertyIsOwner',
		label: pageAssets.label_is_land_owner,
		labelKey: 'label_is_land_owner',
		value: answers.propertyIsOwner?.toString() || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [tenthQuestion, setTenthQuestion] = useState<RequestInputs>({
		name: 'declarationExistsOnAddress',
		label: pageAssets.label_declaration_exists_for_address,
		labelKey: 'label_declaration_exists_for_address',
		value: answers.declarationExistsOnAddress?.toString() || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputs>({
		name: 'subventionExistsOnAddress',
		label: pageAssets.label_subvention_exists_for_address,
		labelKey: 'label_subvention_exists_for_address',
		value: answers.subventionExistsOnAddress?.toString() || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const onSetSecondQuestion = (value: string) => {
		setSecondQuestion({ ...secondQuestion, value })

		onFixError &&
			onFixError(UpdateRequestInputsErrorsArray(errors!, secondQuestion.name))
	}

	useEffect(() => {
		onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		if (shouldDisplaySelectOwnedMoreThenAYearOptions()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, fourthQuestion))
		}
	}, [])

	useEffect(() => {
		if (isTreePlantingForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, fourthQuestion))
		}
	}, [fourthQuestion])

	useEffect(() => {
		if (isTreePlantingForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, fifthQuestion))
		}
	}, [fifthQuestion])

	useEffect(() => {
		if (isTreePlantingForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, sixthQuestion))
		}
	}, [sixthQuestion])

	useEffect(() => {
		if (inputs && isTemporaryUseOfPesticideForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, ninethQuestion))
		}
	}, [ninethQuestion])

	useEffect(() => {
		if (inputs && isFireplaceGrantForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, tenthQuestion))
		}
	}, [tenthQuestion])

	useEffect(() => {
		if (inputs && isFireplaceGrantForm()) {
			onSetInputs &&
				onSetInputs(UpdateRequestInputsArray(inputs!, eleventhQuestion))
		}
	}, [eleventhQuestion])

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: REQUEST_CONFIGS_KEYS.yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: REQUEST_CONFIGS_KEYS.no,
			key: 'request_form_select_no'
		}
	]

	const radioListTrueFalse: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const radioListAddressOrLot: RadioList[] = [
		{
			label: pageAssets.request_form_select_adress,
			value: REQUEST_CONFIGS_KEYS.buildingLocation.byAdress,
			key: 'request_form_select_adress'
		},
		{
			label: pageAssets.request_form_select_lot,
			value: REQUEST_CONFIGS_KEYS.buildingLocation.byLot,
			key: 'request_form_select_lot'
		}
	]

	const radioListLocationOwnedMoreThenAYear: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const onSelectFirstQuestion = (value: string) => {
		setFirstQuestion({
			...firstQuestion,
			value
		})
		if (value == radioListYesNo[1].value) {
			setAddressShouldChange(true)
			setIsRequired && setIsRequired(true)
		} else {
			setAddressShouldChange(false)
			setIsRequired && setIsRequired(false)
			onFixError &&
				onFixError(errors!.filter((err) => err !== AddressInputName))
		}
	}

	const onSelectThirdQuestion = (value: string) => {
		if (value == radioListAddressOrLot[1].value) {
			setThirdQuestion({
				...thirdQuestion,
				value
			})
			setSelectByLot(true)
			setIsRequired && setIsRequired(true)
		} else {
			setThirdQuestion({
				...thirdQuestion,
				value
			})
			setSelectByLot(false)
			setIsRequired && setIsRequired(false)
			onFixError &&
				onFixError(errors!.filter((err) => err !== AddressInputName))
		}
	}

	const onSelectFourthQuestion = (value: string) => {
		if (value == radioListLocationOwnedMoreThenAYear[1].value) {
			setFourthQuestion({
				...fourthQuestion,
				value
			})
			setFifthQuestion({
				...fifthQuestion,
				required: true
			})
			setSixthQuestion({
				...sixthQuestion,
				required: true
			})
		} else {
			setFourthQuestion({
				...fourthQuestion,
				value
			})

			setFifthQuestion({
				...fifthQuestion,
				required: false
			})
			setSixthQuestion({
				...sixthQuestion,
				required: false
			})

			onFixError &&
				onFixError(
					errors!.filter(
						(err) =>
							![
								fourthQuestion.name,
								fifthQuestion.name,
								sixthQuestion.name
							].includes(err)
					)
				)
		}
	}

	const onSelectFifthQuestion = (value: string) => {
		setFifthQuestion({
			...fifthQuestion,
			value
		})

		onFixError &&
			onFixError(errors!.filter((err) => err !== fifthQuestion.name))
	}

	const onSelectSixthQuestion = (value: string) => {
		setSixthQuestion({
			...sixthQuestion,
			value
		})

		onFixError &&
			onFixError(errors!.filter((err) => err !== sixthQuestion.name))
	}

	const onSelectNinethQuestion = (value: string) => {
		setNinethQuestion({
			...ninethQuestion,
			value
		})

		onFixError &&
			onFixError(errors!.filter((err) => err !== ninethQuestion.name))
	}

	const onSelectTenthQuestion = (value: string) => {
		setTenthQuestion({
			...tenthQuestion,
			value
		})

		onFixError &&
			onFixError(errors!.filter((err) => err !== tenthQuestion.name))
	}

	const onSelectEleventhQuestion = (value: string) => {
		setEleventhQuestion({
			...eleventhQuestion,
			value
		})

		onFixError &&
			onFixError(errors!.filter((err) => err !== eleventhQuestion.name))
	}

	const updateAddressInput = (input: string) => {
		if (input && onAddressInputChange && onFixError) {
			onAddressInputChange(input)
			onFixError(errors!.filter((err) => err !== AddressInputName))
		}
	}

	const getCadastre = (value?: string) => {
		if (value) {
			setCadastre(value)
			setSecondQuestion({
				...secondQuestion,
				value,
				required: true
			})
		} else {
			setCadastre('')
			setSecondQuestion({
				...secondQuestion,
				value: '',
				required: false
			})
		}
	}

	useEffect(() => {
		if (onAddressInputChange && onRetreiveCoordinates && !addressShouldChange) {
			onAddressInputChange(authUser?.profile?.address.address || '')
			onRetreiveCoordinates(
				authUser?.profile?.address.coordinates as Coordinate
			)
		}

		if (!addressShouldChange && onAddressChange) {
			onAddressChange({
				name: authUser?.profile?.address.address || '',
				coordinates: authUser?.profile?.address.coordinates as Coordinate,
				postalCode: authUser?.profile?.address.postalCode || '',
				city: authUser?.profile?.address.city || '',
				civic: ''
			})
		}
		if (setUseDefaultUserLocation) {
			setUseDefaultUserLocation(!addressShouldChange)
		}
	}, [addressShouldChange])

	useEffect(() => {
		if (
			thirdQuestion.value === radioListAddressOrLot[0].value ||
			!addressShouldChange
		) {
			setSecondQuestion({
				...secondQuestion,
				required: false
			})

			onFixError &&
				onFixError(UpdateRequestInputsErrorsArray(errors!, secondQuestion.name))
		} else {
			setSecondQuestion({
				...secondQuestion,
				value: '',
				required: true
			})

			setCadastre('')
		}
	}, [selectByLot])

	useEffect(() => {
		if (setMapLabel) {
			isTreePlantingForm()
				? setMapLabel(pageAssets?.request_form_step_map_location)
				: setMapLabel(pageAssets?.request_form_step_map_building)
		}
		if (firstQuestion && firstQuestion.value == REQUEST_CONFIGS_KEYS.no) {
			setAddressShouldChange(true)
			setIsRequired && setIsRequired(true)
		}
	}, [])

	const isTreePlantingForm = (): boolean => {
		return String(form).includes(TNRequestNames.treePlanting)
	}

	const isTemporaryUseOfPesticideForm = (): boolean => {
		return String(form).includes(
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals
		)
	}

	const isFireplaceGrantForm = (): boolean => {
		return String(form).includes(TNRequestNames.fireplaceGrant)
	}

	const shouldDisplaySelectOwnedMoreThenAYearOptions = (): boolean => {
		return [TNRequestNames.treePlanting].includes(String(form))
	}

	const shouldDisplaySelectByLotOptions = (): boolean => {
		return [
			TNRequestNames.heritageBuildingsRevitalizationProgram,
			TNRequestNames.treePlanting,
			TNRequestNames.buildingTransactionRequest,
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
			TNRequestNames.municipalEvaluation
		].includes(String(form))
	}

	const shouldDisplayConcernedInfo = (): boolean => {
		return [TNRequestNames.buildingTransactionRequest].includes(String(form))
	}

	useEffect(() => {
		if (firstQuestion.value == REQUEST_CONFIGS_KEYS.yes) {
			getCadastre()
		}

		if (
			firstQuestion.value == REQUEST_CONFIGS_KEYS.yes &&
			setShouldCheckAddress &&
			form === TNRequestNames.rainwaterBarrel
		) {
			setShouldCheckAddress(true)
		} else {
			setShouldCheckAddress && setShouldCheckAddress(false)
		}
	}, [firstQuestion])

	useEffect(() => {
		// verifier si adresse est en dehors de Laval (no coordinates)
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)
		if (
			firstQuestion.value == REQUEST_CONFIGS_KEYS.yes &&
			!coordinates.validate()
		) {
			setIsRequestAddressOutsideLaval && setIsRequestAddressOutsideLaval(true)
		} else {
			setIsRequestAddressOutsideLaval && setIsRequestAddressOutsideLaval(false)
			setIsAddressOutsideLavalMessageShow &&
				setIsAddressOutsideLavalMessageShow(false)
			ninethQuestion.value !== radioListTrueFalse[1].value &&
				setDisableNextButton &&
				setDisableNextButton(true)
		}
	}, [firstQuestion])

	useEffect(() => {
		getAddressByCoordinates(authUser?.profile?.address.coordinates).then(
			(res) => {
				setUserCadastralNumber(res?.data?.features?.[0].properties.cadastre)
			}
		)
	}, [])

	useEffect(() => {
		if (ninethQuestion.value === radioListTrueFalse[1].value) {
			setIsModalOpen(true)
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}
	}, [ninethQuestion])

	useEffect(() => {
		if (tenthQuestion.value === radioListTrueFalse[1].value) {
			setIsTenthQuestionNotificationOpen(true)
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setIsTenthQuestionNotificationOpen(false)
			setDisableNextButton &&
				!isEleventhQuestionNotificationOpen &&
				setDisableNextButton(true)
		}
	}, [tenthQuestion])

	useEffect(() => {
		if (eleventhQuestion.value === radioListTrueFalse[0].value) {
			setIsEleventhQuestionNotificationOpen(true)
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setIsEleventhQuestionNotificationOpen(false)
			setDisableNextButton &&
				!isTenthQuestionNotificationOpen &&
				setDisableNextButton(true)
		}
	}, [eleventhQuestion])

	const onCloseModal = () => {
		setIsModalOpen(false)
	}
	const onQuitForm = () => {
		if (closeLink) {
			navigate(closeLink)
		}
	}

	const isDisplaySelectByLotOptionsAndShouldAddressChange =
		shouldDisplaySelectByLotOptions() && addressShouldChange

	const renderMapForm = () => {
		return (
			<MapForm
				addressRequired
				classNames={{
					address: joinClasses([classes.formInput, classes.map])
				}}
				hasError={hasError}
				onAddressInputChange={updateAddressInput}
				onAddressChange={onAddressChange}
				onApartmentInputChange={onApartmentInputChange}
				onRetreiveCoordinates={onRetreiveCoordinates}
				onAddError={onAddError}
				onSetPostalCode={(value) => {
					onSetPostalCode && onSetPostalCode(value)
					setPostalCode(value)
				}}
				onSetCity={onSetCity}
				displayAptInput={isFireplaceGrantForm()}
				onSetCadastre={getCadastre}
				disableLocationIcon={selectByLot}
				searchByCadastralNumber={selectByLot}
			/>
		)
	}

	return (
		<>
			<h4 className={classes.h4}>
				<Text
					content={
						isTreePlantingForm()
							? pageAssets.request_form_step_map_location
							: isTemporaryUseOfPesticideForm()
							? pageAssets.request_form_step_map_information_relating_to_the_property
							: pageAssets.request_form_step_map_building
					}
				/>
			</h4>

			<div className={classes.label}>
				<strong>{formatStrapiText(pageAssets.label_address)}</strong>
				<br />
				{shouldDisplayConcernedInfo() && !answers?.isApplicantLotOwner ? (
					<span>
						{answers?.concernedAdresse}
						<br />
						{answers?.concernedCity}
						{` `}
						{answers?.concernedPostalCode}
						<br />
						{answers?.concernedAppartement
							? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
									answers?.concernedAppartement
							  }`
							: ''}
					</span>
				) : (
					<span>
						{authUser?.profile?.address.address}
						<br />
						{authUser?.profile?.address.city}
						{authUser?.profile?.address.state
							? ` (${authUser?.profile?.address.state}) `
							: ' '}
						{authUser?.profile?.address?.postalCode}
						<br />
						{authUser?.profile?.address.apartment
							? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
									authUser?.profile?.address.apartment
							  }`
							: ''}
					</span>
				)}
			</div>

			<div
				className={isTemporaryUseOfPesticideForm() ? classes.fieldWrapper : ''}
			>
				<RadioInput
					name={firstQuestion.name}
					label={firstQuestion.label}
					value={firstQuestion.value}
					list={radioListYesNo}
					hasError={errors!.includes(firstQuestion.name)}
					onChange={onSelectFirstQuestion}
					hasBackgroundWrapper
					ref={firstQuestion.ref as RefObject<HTMLInputElement>}
				/>
			</div>

			{addressShouldChange && (
				<>
					<div className={classes.field}>
						{shouldDisplaySelectByLotOptions() && (
							<RadioInput
								name={thirdQuestion.name}
								label={thirdQuestion.label}
								value={thirdQuestion.value}
								list={radioListAddressOrLot}
								required={addressShouldChange}
								direction={Direction.horizontal}
								hasError={errors!.includes(thirdQuestion.name)}
								onChange={onSelectThirdQuestion}
								ref={thirdQuestion.ref as RefObject<HTMLInputElement>}
							/>
						)}

						<>
							<div className={classes.field}>
								{!shouldDisplaySelectByLotOptions() && (
									<label
										className={joinClasses([
											classes.formLabel,
											classes.required,
											hasError ? classes.labelError : ''
										])}
										style={{ marginBottom: hasError ? '12px' : '' }}
									>
										{formatStrapiText(
											pageAssets?.request_form_address_of_the_building
										)}
									</label>
								)}

								{shouldDisplaySelectByLotOptions() && renderMapForm()}
								{!shouldDisplaySelectByLotOptions() && renderMapForm()}
								{cadastre && isDisplaySelectByLotOptionsAndShouldAddressChange && (
									<span style={{ marginBottom: '25px', display: 'block' }}>
										<Text
											content={`${pageAssets.lot_number_label}: ${cadastre}`}
										/>
									</span>
								)}
							</div>
						</>
					</div>
				</>
			)}

			{isAddressOutsideLavalMessageShow && (
				<Notification
					type="warning"
					text={
						pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
					}
				/>
			)}

			{shouldDisplaySelectOwnedMoreThenAYearOptions() && (
				<>
					<RadioInput
						name={fourthQuestion.name}
						label={fourthQuestion.label}
						value={fourthQuestion.value}
						list={radioListLocationOwnedMoreThenAYear}
						direction={Direction.vertical}
						hasError={errors!.includes(fourthQuestion.name)}
						onChange={onSelectFourthQuestion}
						ref={fourthQuestion.ref as RefObject<HTMLInputElement>}
						hasBackgroundWrapper
					/>

					{fourthQuestion.value == 'false' && (
						<>
							<div className={classes.fieldsContainer}>
								<DateFilterInput
									id={fifthQuestion.name}
									label={fifthQuestion.label}
									value={fifthQuestion.value}
									onChange={onSelectFifthQuestion}
									hasError={errors!.includes(fifthQuestion.name)}
									required={fifthQuestion.required}
									maxDate={new Date(fifthQuestion.extraParams?.max!)}
									minDate={new Date(fifthQuestion.extraParams?.min!)}
									dateFormat="dd/MM/yyyy"
								/>
								{/* 
								<TextInput
									type="date"
									max={fifthQuestion.extraParams?.max}
									min={fifthQuestion.extraParams?.min}
									id={fifthQuestion.name}
									label={fifthQuestion.label}
									value={fifthQuestion.value}
									onChange={onSelectFifthQuestion}
									hasError={errors!.includes(fifthQuestion.name)}
									required={fifthQuestion.required}
									ref={fifthQuestion.ref as RefObject<HTMLInputElement>}
									customErrorText={
										fifthQuestion.value !== ''
											? fifthQuestion.extraParams?.customErrorText
											: undefined
									}
								/>
								*/}
							</div>

							<TextInput
								id={sixthQuestion.name}
								label={sixthQuestion.label}
								value={sixthQuestion.value}
								onChange={onSelectSixthQuestion}
								hasError={errors!.includes(sixthQuestion.name)}
								required={sixthQuestion.required}
								ref={sixthQuestion.ref as RefObject<HTMLInputElement>}
							/>
						</>
					)}
				</>
			)}

			{isTemporaryUseOfPesticideForm() && (
				<div
					className={
						isTemporaryUseOfPesticideForm() ? classes.fieldWrapper : ''
					}
				>
					<RadioInput
						name={ninethQuestion.name}
						label={ninethQuestion.label}
						value={ninethQuestion.value}
						list={radioListTrueFalse}
						direction={Direction.vertical}
						hasError={errors!.includes(ninethQuestion.name)}
						required={ninethQuestion.required}
						onChange={onSelectNinethQuestion}
						hasBackgroundWrapper
						ref={ninethQuestion.ref as RefObject<HTMLInputElement>}
					/>
				</div>
			)}

			{isFireplaceGrantForm() && (
				<div className={isFireplaceGrantForm() ? classes.fieldWrapper : ''}>
					<RadioInput
						name={tenthQuestion.name}
						label={tenthQuestion.label}
						value={tenthQuestion.value}
						list={radioListTrueFalse}
						direction={Direction.vertical}
						hasError={errors!.includes(tenthQuestion.name)}
						required={tenthQuestion.required}
						onChange={onSelectTenthQuestion}
						hasBackgroundWrapper
						ref={tenthQuestion.ref as RefObject<HTMLInputElement>}
					/>

					{isTenthQuestionNotificationOpen && (
						<Notification
							type="error"
							text={formatHtmlForStrapiText(
								pageAssets?.request_form_declaration_already_exists_notification_label
							)}
							hasHtml
						/>
					)}

					<RadioInput
						name={eleventhQuestion.name}
						label={eleventhQuestion.label}
						value={eleventhQuestion.value}
						list={radioListTrueFalse}
						direction={Direction.vertical}
						hasError={errors!.includes(eleventhQuestion.name)}
						required={eleventhQuestion.required}
						onChange={onSelectEleventhQuestion}
						hasBackgroundWrapper
						ref={eleventhQuestion.ref as RefObject<HTMLInputElement>}
					/>
					{isEleventhQuestionNotificationOpen && (
						<Notification
							type="error"
							text={
								pageAssets?.request_form_subvention_already_exists_notification_label
							}
						/>
					)}
				</div>
			)}

			<DisplayInforModal
				isModalOpen={isModalOpen}
				message={
					pageAssets?.modal_info_you_must_be_an_owner_to_submit_the_request
				}
				close={onCloseModal}
				onPrimaryButton={onQuitForm}
				cancelButtonText={pageAssets?.label_return_to_form}
				primaryButtonText={pageAssets?.label_quit}
			/>

			{renderAddressValidation && renderAddressValidation()}
		</>
	)
}

export default AddressForm
