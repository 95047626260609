import AddressForm from '@components/ui/addressForm'
import React, { FC, useEffect } from 'react'
import DefaultMap from './defaultMap.form'
import { MapTNProps } from './__forms.utils'

type mapFormFactory = {
	[key: string]: FC<MapTNProps>
}

const MapSelection: FC<MapTNProps> = ({
	form,
	errors,
	inputs,
	hasError,
	addressRequired,
	onFixError,
	onAddressInputChange,
	onRetreiveCoordinates,
	onAddError,
	onSetInputs,
	onApartmentInputChange,
	setIsRequired,
	onSetCity,
	onSetPostalCode,
	setMapLabel,
	onSetCadastralAddress,
	setUseDefaultUserLocation,
	setDisableNextButton,
	closeLink,
	renderAddressValidation,
	setShouldCheckAddress,
	setIsRequestAddressOutsideLaval,
	isAddressOutsideLavalMessageShow,
	setIsAddressOutsideLavalMessageShow
}) => {
	const mapFormFactory: mapFormFactory = {
		rainwaterBarrel: AddressForm,
		replacementOilHeatingSystem: AddressForm,
		heritageBuildingsRevitalizationProgram: AddressForm,
		crackedHouses: AddressForm,
		treePlanting: AddressForm,
		temporaryPesticideApplicationPermitForIndividuals: AddressForm,
		buildingTransactionRequest: AddressForm,
		temporaryUseOfPesticide: AddressForm,
		municipalEvaluation: AddressForm,
		fireplaceGrant: AddressForm
	}

	const MapComponentForm: FC<MapTNProps> = mapFormFactory[form!] || DefaultMap

	const shouldMapDisplay = {
		rainwaterBarrel: false,
		replacementOilHeatingSystem: false,
		crackedHouses: false,
		treePlanting: false,
		temporaryPesticideApplicationPermitForIndividuals: false,
		buildingTransactionRequest: false,
		municipalEvaluation: false,
		fireplaceGrant: false
	}

	return (
		<>
			<MapComponentForm
				form={form}
				errors={errors}
				inputs={inputs}
				hasError={hasError}
				onFixError={onFixError}
				onSetInputs={onSetInputs}
				onAddressInputChange={onAddressInputChange}
				onRetreiveCoordinates={onRetreiveCoordinates}
				onAddError={onAddError}
				addressRequired={addressRequired}
				displayMap={shouldMapDisplay[form!]}
				displayAptInput={shouldMapDisplay[form!]}
				showButtonUserLocation={shouldMapDisplay[form!]}
				onApartmentInputChange={onApartmentInputChange}
				setIsRequired={setIsRequired}
				onSetCity={onSetCity}
				setMapLabel={setMapLabel}
				setUseDefaultUserLocation={setUseDefaultUserLocation}
				onSetPostalCode={onSetPostalCode}
				onAddressChange={(address) => {
					onSetPostalCode && onSetPostalCode(address.postalCode)
					onSetCadastralAddress &&
						onSetCadastralAddress(address.cadastralAddress)
				}}
				setDisableNextButton={setDisableNextButton}
				closeLink={closeLink}
				renderAddressValidation={renderAddressValidation}
				setShouldCheckAddress={setShouldCheckAddress}
				setIsRequestAddressOutsideLaval={setIsRequestAddressOutsideLaval}
				isAddressOutsideLavalMessageShow={isAddressOutsideLavalMessageShow}
				setIsAddressOutsideLavalMessageShow={
					setIsAddressOutsideLavalMessageShow
				}
			/>
		</>
	)
}

export default MapSelection
