import MapForm from '@components/ui/map/MapForm'
import React, { FC, useEffect } from 'react'
import requestForm from './request.form'
import Text from '@components/ui/text'
import * as pageUtils from '@templates/requests/__create.utils'
import { MapTNProps } from './__forms.utils'

const DefaultMap: FC<MapTNProps> = ({
	hasError,
	addressRequired,
	displayMap,
	displayAptInput,
	showButtonUserLocation,
	onAddError,
	onAddressInputChange,
	onApartmentInputChange,
	onRetreiveCoordinates,
	setIsRequired,
	onAddressChange,
	setMapLabel
}) => {
	const { pageAssets } = requestForm()

	useEffect(() => {
		setMapLabel && setMapLabel(pageAssets?.request_form_step_map)
		setIsRequired && setIsRequired(true)
	}, [])

	return (
		<>
			<h3 className={pageUtils.classes.h3}>
				<Text content={pageAssets?.request_form_step_map} />
			</h3>
			<Text content={pageAssets?.page_requestInfo_location_text} />
			<MapForm
				addressPlaceholder={
					pageAssets?.page_requestInfo_inputAddressPlaceholder
				}
				hasError={hasError}
				addressRequired={addressRequired}
				displayMap={displayMap}
				displayAptInput={displayAptInput}
				showButtonUserLocation={showButtonUserLocation}
				onAddressInputChange={onAddressInputChange}
				onRetreiveCoordinates={onRetreiveCoordinates}
				onApartmentInputChange={onApartmentInputChange}
				onAddError={onAddError}
				onAddressChange={onAddressChange}
			/>
		</>
	)
}

export default DefaultMap
